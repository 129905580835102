/* Reset */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Mono:wght@400;500;600;700&display=swap");
html,
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}

/* Fonts */
/* Colors */
/* Dashboard */
.dashboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* Components */
.adform {
  background-color: #f8f8f8;
  border-radius: 1.25rem;
  color: #444444;
  margin: 2rem 1.5rem;
  padding: 2rem;
}

.adform__btn {
  background-color: #1d5c87;
  border: none;
  border-radius: 1rem;
  color: #fff;
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  height: 3.5rem;
  margin-left: auto;
  margin-right: 0;
  margin-top: 3.75rem;
  text-transform: uppercase;
  width: 11.25rem;
}

.adform__btn:active {
  background-color: #174b6d;
}

.adform__field--adtype {
  padding-top: 2rem;
}

.adform__field--adtype__title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.adform__field__options__checkbox {
  margin-top: 1.125rem;
}

.adform__field__options__checkbox--input {
  margin-right: 0.75rem;
}

.adform__field__options__checkbox--label {
  font-size: 1.125rem;
  font-weight: 500;
}

.adform__field--input {
  border: none;
  border-radius: 1rem;
  font-size: 1.25rem;
  height: 3.5rem;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  padding: 0 1rem;
  width: 100%;
}

.adform__field--input--enddate {
  margin-bottom: 0;
}

.adform__field--input:focus {
  outline: none;
}

.adform__field__text--checkbox {
  margin: 2rem 0;
}

.adform__field__text--help {
  display: none;
}

.adform__field__text--label {
  font-size: 1.25rem;
  font-weight: 700;
}

.adform__field__time {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3.5rem;
  margin-top: 0.5rem;
}

.adform__field__time--input {
  margin-bottom: 0;
  margin-right: 1rem;
  margin-top: 0;
  max-width: 40%;
}

.adform__hr {
  border: 0.5px solid #c4c4c4;
  margin-top: 2.75rem;
}

@media (min-width: 1280px) {
  .adform__field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .adform__field__text {
    width: 20rem;
  }
  .adform__field__text--checkbox {
    margin: 0;
  }
  .adform__field__text--help {
    display: block;
    margin-top: 0.25rem;
  }
  .adform__field--input {
    width: 20rem;
    margin-top: 0;
  }
  .adform__field--input--enddate {
    margin-bottom: 2rem;
  }
  .adform__field__options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    width: 45rem;
  }
  .adform__field__options__checkbox {
    margin-top: 0;
    margin-bottom: 1.125rem;
    width: 50%;
  }
}

.loginform {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 3.375rem;
  padding-top: 5.375rem;
}

.loginform__input {
  border: none;
  border-radius: 1rem;
  height: 3.5rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.loginform__input--btn {
  background-color: #1d5c87;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
}

.loginform__input--btn:active {
  background-color: #174b6d;
}

.loginform__input--btn:hover {
  cursor: pointer;
}

.loginform__input--error {
  background-color: #ffebe8;
  border: 2px solid #dd3c10;
  border-radius: 0;
  color: #dd3c10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-weight: 700;
  font-size: 0.875rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
  text-align: center;
}

.loginform__input__field {
  color: #1a6698;
  font-size: 1.125rem;
  font-weight: 500;
  padding-left: 4rem;
  width: 100%;
}

.loginform__input__field:focus {
  outline: none;
}

.loginform__input__field::-webkit-input-placeholder {
  color: #1a6698;
}

.loginform__input__field:-ms-input-placeholder {
  color: #1a6698;
}

.loginform__input__field::-ms-input-placeholder {
  color: #1a6698;
}

.loginform__input__field::placeholder {
  color: #1a6698;
}

.loginform__input__icon {
  height: 1.5rem;
  left: 1rem;
  position: absolute;
  top: 1rem;
  width: auto;
  z-index: 10;
}

@media only screen and (min-width: 768px) {
  .loginform {
    padding: 0 15rem;
    padding-top: 8.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .loginform {
    padding: 0 22.5rem;
    padding-top: 8.5rem;
  }
}

@media only screen and (min-width: 1366px) {
  .loginform {
    padding: 0 30rem;
    padding-top: 8.5rem;
  }
}

@media only screen and (min-width: 1440px) {
  .loginform {
    padding: 0 35rem;
    padding-top: 8.5rem;
  }
}

.mobile--menu {
  background-color: #ed7c52;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  left: -100%;
  top: 6.25rem;
  padding: 1rem;
  position: absolute;
  text-align: center;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  width: 100%;
  z-index: 10;
}

.mobile--menu--active {
  left: 0;
}

.mobile--menu__navlinks__navlink {
  border-radius: 1rem;
  color: #fff;
  font-size: 1.125rem;
  padding: 1rem 0;
  text-decoration: none;
}

.mobile--menu__navlinks__navlink--logout {
  margin-top: 2.5rem;
}

@media only screen and (min-width: 1024px) {
  .mobile--menu {
    display: none;
  }
}

.mobile--navbar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ed7c52;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 6.25rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 1rem;
  position: fixed;
  width: 100vw;
  z-index: 15;
}

.mobile--navbar__icon {
  color: #fff;
  width: 2.25rem;
}

.mobile--navbar__logo {
  width: 3.5rem;
}

.mobile--navbar__title {
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (min-width: 375px) {
  .mobile--navbar__title {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .mobile--navbar__icon {
    width: 3rem;
  }
  .mobile--navbar__logo {
    width: 4rem;
  }
  .mobile--navbar__title {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1024px) {
  .mobile--navbar {
    display: none;
  }
}

.navbar {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .navbar {
    background-color: #ed7c52;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    position: fixed;
    top: 0;
    width: 14.65rem;
    z-index: 5;
  }
  .navbar--collapsed {
    width: 5rem;
  }
  .navbar__logo {
    margin: 2.75rem;
    margin-bottom: 0;
    width: 9.375rem;
  }
  .navbar__logo--collapsed {
    margin: 0 auto;
    margin-top: 2.75rem;
    margin-bottom: 0;
    width: 3rem;
  }
  .navbar__navlinks {
    margin-top: 7rem;
  }
  .navbar__navlinks--collapsed {
    margin-top: 13.375rem;
  }
  .navbar__navlinks__navlink {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 500;
    height: 2.5rem;
    margin-bottom: 2.25rem;
    padding-left: 1.75rem;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .navbar__navlinks__navlink--icon {
    margin-right: 1rem;
    width: 1.5rem;
  }
  .navbar__navlinks__navlink--logout {
    border: none;
    margin-top: 5rem;
  }
  .navbar-toggle-expanded,
  .navbar-toggle-collapsed {
    color: #fff;
    padding-left: 1.75rem;
    position: absolute;
    bottom: 2.5rem;
  }
  .navbar-toggle-expanded:hover,
  .navbar-toggle-collapsed:hover {
    cursor: pointer;
  }
}

.otpform {
  color: #fff;
  padding: 0 2.75rem;
  padding-top: 2.5rem;
  text-align: center;
}

.otpform__btn {
  border: none;
  border-radius: 1rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  height: 3.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.otpform__btn:hover {
  cursor: pointer;
}

.otpform__btn--resend {
  background-color: transparent;
  border: solid #fff 3px;
}

.otpform__btn--resend:disabled {
  background-color: #c4c4c4;
  border: none;
}

.otpform__btn--submit {
  background-color: #1d5c87;
}

.otpform__btn--submit:active {
  background-color: #174b6d;
}

.otpform__header {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.otpform__input {
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-bottom: 3rem;
  width: 100%;
}

.otpform__input input {
  background: none;
  background-position: bottom;
  background-size: 100px 3px;
  border: 0;
  border-bottom: 3px solid rgba(68, 68, 68, 0.45);
  color: #fff;
  font-family: "Roboto Mono", monospace;
  font-size: 3rem;
  font-weight: 700;
}

.otpform__input input:focus {
  border-bottom: 3px solid #444444;
  outline: none;
}

.otpform__snackbar {
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 700;
  font-size: 0.875rem;
  height: 3.5rem;
  margin-bottom: 0;
}

.otpform__snackbar--error {
  background-color: #ffebe8;
  border: 2px solid #dd3c10;
  color: #dd3c10;
}

.otpform__snackbar--resend {
  background-color: #def2d6;
  border: 2px solid #586f52;
  color: #586f52;
}

.otpform__subtext {
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 399px) {
  .otpform {
    padding: 0 1rem;
    padding-top: 2.5rem;
  }
  .otpform div .otpform__input {
    max-width: 100%;
  }
  .otpform div .otpform__input input {
    font-size: 2.75rem;
  }
}

@media only screen and (min-width: 768px) {
  .otpform {
    padding-top: 3.5rem;
  }
  .otpform div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    width: 25.5rem;
  }
  .otpform div__input {
    width: 4.25rem;
  }
  .otpform div__input input {
    width: 4rem;
  }
  .otpform__btn {
    margin-bottom: 1.5rem;
    width: 20rem;
  }
  .otpform__btn--submit {
    margin-right: 1.5rem;
  }
  .otpform__snackbar {
    margin: 0 auto;
    width: 20rem;
  }
}

.topbar {
  padding-top: 6.25rem;
}

.topbar .hidden {
  display: none;
}

.topbar__title,
.topbar__functions__userdetails {
  display: none;
}

.topbar__toggle {
  background-color: rgba(29, 92, 135, 0.75);
  color: #fff;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  background-color: white;
  color: #1d5c87;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0;
  text-align: center;
}

.topbar__functions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.topbar__functions__fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.topbar__functions__fields--btn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1d5c87;
  border: none;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  height: 2.625rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
}

.topbar__functions__fields--btn:active {
  background-color: #174b6d;
}

.topbar__functions__fields--btn:hover {
  cursor: pointer;
}

@media only screen and (min-width: 1024px) {
  .topbar {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-bottom: 2px solid #f0f0f0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 6.25rem;
    padding: 0 2.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .topbar__toggle {
    display: none;
  }
  .topbar__functions {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: initial;
    -webkit-box-direction: initial;
    -ms-flex-direction: initial;
    flex-direction: initial;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: auto;
  }
  .topbar__functions__fields {
    border-right: 2px solid #1a6698;
    padding-right: 2.25rem;
  }
  .topbar__functions__fields--btn {
    border-radius: 0.625rem;
    font-size: 0.9rem;
    width: 8rem;
  }
  .topbar__functions__fields--searchfield {
    margin-right: 1.5rem;
  }
  .topbar__functions__fields--searchfield__dropdown,
  .topbar__functions__fields--searchfield__search {
    font-size: 0.9rem;
    width: 11rem;
  }
  .topbar__functions__fields--searchfield__dropdown {
    border-radius: 0.625rem 0 0 0.625rem;
  }
  .topbar__functions__fields--searchfield__search {
    border-left: none;
    border-radius: 0 0.625rem 0.625rem 0;
    padding-right: 2.25rem;
  }
  .topbar__functions__fields--searchfield__search--enddate,
  .topbar__functions__fields--searchfield__search--startdate {
    padding-right: 0rem;
    width: 5.5rem;
  }
  .topbar__functions__fields--searchfield__search--startdate {
    border-right: 1px solid #1d5c87;
    border-radius: 0;
  }
  .topbar__title {
    color: #1d5c87;
    display: initial;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 1366px) {
  .topbar__functions__fields--btn {
    border-radius: 0.625rem;
    width: 11.5rem;
  }
  .topbar__functions__fields--searchfield {
    margin-right: 2rem;
  }
  .topbar__functions__fields--searchfield__dropdown {
    width: 12rem;
  }
  .topbar__functions__fields--searchfield__search {
    width: 17.5rem;
    padding-right: 3rem;
  }
  .topbar__functions__fields--searchfield__search--enddate,
  .topbar__functions__fields--searchfield__search--startdate {
    padding-right: 0;
    width: 9rem;
  }
  .topbar__functions__userdetails {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 2.25rem;
  }
  .topbar__functions__userdetails--photo {
    border: 1px solid #fff;
    border-radius: 50%;
    width: 2.625rem;
  }
  .topbar__functions__userdetails--text {
    font-size: 0.9rem;
    font-weight: 500;
    padding-top: 0.25rem;
  }
  .topbar__title {
    color: #1d5c87;
    display: initial;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 1440px) {
  .topbar__functions__fields--searchfield {
    margin-right: 2rem;
  }
  .topbar__functions__fields--searchfield__dropdown {
    font-size: 1rem;
    width: 13rem;
  }
  .topbar__functions__fields--searchfield__search {
    font-size: 1rem;
    width: 20rem;
    padding-right: 3rem;
  }
  .topbar__functions__fields--searchfield__search--enddate,
  .topbar__functions__fields--searchfield__search--startdate {
    padding-right: 0;
    width: 10rem;
  }
  .topbar__functions__userdetails {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 2.25rem;
  }
  .topbar__functions__userdetails--photo {
    border: 1px solid #fff;
    border-radius: 50%;
    width: 2.625rem;
  }
  .topbar__functions__userdetails--text {
    font-size: 1rem;
    font-weight: 500;
    padding-top: 0.25rem;
  }
  .topbar__title {
    color: #1d5c87;
    display: initial;
    font-size: 1.75rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}
